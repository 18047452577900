import React from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
// Import Images (if inside src/)
import fruitsImg from "../assets/images/home/freeze-dry-mix-fruits.jpg";
import vegetablesImg from "../assets/images/home/freeze-dry-vegetables.webp";
import herbsImg from "../assets/images/home/freeze-dry-herbs.webp";

const products = [
  {
    id: "fruits",
    name: "Freeze Dried Fruits",
    img: fruitsImg,
    url: "/product#fruits",
    animationDuration: "1000",
  },
  {
    id: "vegetables",
    name: "Freeze Dried Vegetables",
    img: vegetablesImg,
    url: "/product#vegetables",
    animationDuration: "2000",
  },
  {
    id: "herbs",
    name: "Freeze Dried Herbs/superfoods",
    img: herbsImg,
    url: "/product#herbs",
    animationDuration: "3000",
  },
  {
    id: "readyToEat",
    name: "Ready to eat foods",
    img: herbsImg,
    url: "/product#readyToEat",
    animationDuration: "4000",
  },
];
export const ProductSection = () => {
  return (
  <section className="section product-wrapper">
      <Container>
        {/* Section Title */}
        <Row className="justify-content-center text-center mb-4">
          <Col xs={12}>
            <div className="section-title">
                <h2>Our Products</h2>
            </div>
          </Col>
        </Row>

        {/* Product List */}
        <Row>
          {products.map((product) => (
            <Col key={product.id} lg={3} md={6} sm={12} data-aos="fade-up" data-aos-duration={product.animationDuration}>
              <div className="product-wrap text-center">
                <Link to={product.url} className="d-block text-decoration-none text-dark">
                  <div className="product-image mb-3">
                    <Image src={product.img} alt={product.name} fluid />
                  </div>
                  <h5>{product.name}</h5>
                </Link>
              </div>
            </Col>
          ))}
        </Row>

        {/* View All Button */}
        <Row className="mt-4">
          <Col className="text-center">
            <Button variant="outline-primary" as={Link} to="/product">
              View All
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
