    import React from "react";
    import babyFoodImg from "../assets/images/application/baby-food.jpeg";
    import snacksImg from "../assets/images/application/snacks.jpeg";
    import toppingImg from "../assets/images/application/topping.jpeg";
    import smoothiesImg from "../assets/images/application/smoothies.jpeg";
    import saladImg from "../assets/images/application/salad.jpeg";
    const applications = [
    {
        title: "Baby Food",
        description: "Mix essential freeze dried fruits in baby food deliver high nutrient value.",
        image: babyFoodImg
    },
    {
        title: "Snacks",
        description: "Eat it by itself or use part of trail mix or snack mix.",
        image: snacksImg
    },
    {
        title: "Topping",
        description: "Sprinkle it on cereal, yogurt, ice-cream, wafers or french toast.",
        image: toppingImg
    },
    {
        title: "Smoothies/Cocktail",
        description: "Blend it into smoothies for a velvety texture and intense flavour.",
        image: smoothiesImg
    },
    {
        title: "Salads",
        description: "Toss it into salads for crunchy sweet & tangy contrast.",
        image: saladImg
    },
    ];

    const ApplicationSection = () => {
    return (
        <section className="application-wrapper section pt-0">
        <div className="container">
            <div className="section-title">
            <h2>Application</h2>
            </div>
            <div className="application-wrap">
            <ul>
                {applications.map((app, index) => (
                <li key={index} data-aos="fade-up" data-aos-duration="2000">
                    <div className="app-content">
                    <div className="app-wrap">
                        <div className="app-title">
                        <h4>{app.title}</h4>
                        </div>
                        <div className="app-description">
                        <p>{app.description}</p>
                        </div>
                    </div>
                    <div className="app-image">
                        <img src={app.image} alt={app.title} />
                    </div>
                    </div>
                </li>
                ))}
            </ul>
            </div>
        </div>
        </section>
    );
    };

    export default ApplicationSection;