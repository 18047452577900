import React from 'react'
import { AboutSection } from '../../components/AboutSection'
import { ProductSection } from '../../components/ProductSection'
import { FeaturesSection } from '../../components/FeaturesSection'
import Banner from '../../components/Banner'

export const Home = () => {
  return (
    <div className='home-page first-section'>
      <Banner />
      <AboutSection />
      <ProductSection />
      <FeaturesSection />
    </div>
  )
}
