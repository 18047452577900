import React from "react";
import process1 from "../assets/images/process/process1.png";
import freezeDryer from "../assets/images/process/freeze-dryer-1.jpeg";
import packing from "../assets/images/process/packing.jpeg";
const processes = [
  {
    title: "Fresh fruit from farm",
    description: "Fresh fruits are hand-picked from selected organic farms. Washed, cut, and prepared at our plant.",
    image: process1,
    reverse: false,
  },
  {
    title: "Freeze Drying",
    description: "Chopped material is dried by sublimation and cooling under low pressure, which creates a crunchy texture while preserving the natural taste.",
    image: freezeDryer,
    reverse: true,
  },
  {
    title: "Packing",
    description: "Dried product is packed using a contactless procedure in a triple-laminated bag with a silica pouch inside to improve shelf life.",
    image: packing,
    reverse: false,
  },
];

const ProcessSection = () => {
  return (
    <section className="section process-wrapper pt-0">
      <div className="section-title mb-4">
        <h2>Process</h2>
      </div>
      <div className="container">
        <div className="process-bg">
          {processes.map((process, index) => (
            <div className={`row align-items-center ${process.reverse ? "row-reverse" : ""}`} key={index}>
              {!process.reverse && (
                <div className="col-lg-6 col-md-6 col-sm-12 aos-init" data-aos="fade-right" data-aos-duration="2000">
                  <div className="process-wrap">
                    <h4>{process.title}</h4>
                    <p>{process.description}</p>
                  </div>
                </div>
              )}
              <div className="col-lg-6 col-md-6 col-sm-12 aos-init" data-aos="fade-left" data-aos-duration="2000">
                <div className="process-image">
                  <img src={process.image} alt={process.title} />
                </div>
              </div>
              {process.reverse && (
                <div className="col-lg-6 col-md-6 col-sm-12 aos-init" data-aos="fade-right" data-aos-duration="2000">
                  <div className="process-wrap">
                    <h4>{process.title}</h4>
                    <p>{process.description}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
