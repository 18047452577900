import React, { useState } from 'react';
// import emailjs from "emailjs-com";
import contactImage from "../../assets/images/contact.jpeg";
export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    message: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  // Handle form submission
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const serviceID = "your_service_id"; // Replace with your EmailJS Service ID
  //   const templateID = "your_template_id"; // Replace with your EmailJS Template ID
  //   const userID = "your_user_id"; // Replace with your EmailJS User ID

  //   emailjs.send(serviceID, templateID, formData, userID)
  //     .then((response) => {
  //       console.log("Email Sent Successfully!", response.status, response.text);
  //       alert("Your message has been sent successfully!");
  //       setFormData({ name: "", email: "", phone: "", city: "", message: "" });
  //     })
  //     .catch((err) => {
  //       console.error("Email Send Failed:", err);
  //       alert("Failed to send message. Please try again.");
  //     });
  // };

  return (
    <main className="page contact-page">
      <div className="content-inner p-b0 mb-5">
        <div className="container">
          <div className="section-title mb-4">
            <h2>Contact Us</h2>
          </div>
          <div className="row">
            {/* Office Location Section */}
            <div className="col-lg-4 col-md-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon-bx-wraper">
                <div className="icon-bx p-3">
                  <div className="icon-cell">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <h2 className="title">Office Location</h2>
                </div>
                <div className="icon-content p-3">
                  <p>
                    A-105/106, Rameshwar Estate, Oppo. Krishna park society,
                    Nr. Jay Chemical, Odhav, Ahmedabad-382415
                  </p>
                </div>
              </div>
            </div>

            {/* Send a Message Section */}
            <div className="col-lg-4 col-md-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
              <div className="icon-bx-wraper">
                <div className="icon-bx p-3">
                  <div className="icon-cell">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <h2 className="title">Send a Message</h2>
                </div>
                <div className="icon-content p-3">
                  <p>info@kashyapbio.com</p>
                </div>
              </div>
            </div>

            {/* Call Us Directly Section */}
            <div className="col-lg-4 col-md-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
              <div className="icon-bx-wraper">
                <div className="icon-bx p-3">
                  <div className="icon-cell">
                    <i className="fas fa-mobile-alt"></i>
                  </div>
                  <h2 className="title">Call Us Directly</h2>
                </div>
                <div className="icon-content p-3">
                  <p>Phone: +91 97241 60555</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Google Map Section */}
      <div className="content-inner aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
          <div className="map-iframe map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.892322906019!2d72.64674427047821!3d23.027725476435062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8700bfffffff%3A0x180763eb3f3b741a!2sKashyap%20Auto%20Electronics!5e0!3m2!1sen!2sin!4v1731426912691!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="Google Map Location"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="content-inner section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5 col-md-6 aos-init" data-aos="fade-right" data-aos-duration="2000">
              <img src={contactImage} alt="Contact" />
            </div>
            <div className="col-lg-6 col-md-6 aos-init" data-aos="fade-left" data-aos-duration="2000">
              <div className="contact-area mt-3">
                <div className="section-head">
                  <h2 className="title">Let’s Talk</h2>
                  <p>Contact Us For a Quote. Help Or Join The Team!</p>
                </div>
                {/* <form className="dz-form dzForm row" method="POST" onSubmit={handleSubmit}> */}

                <form className="dz-form dzForm row" method="POST" >
                  <input type="hidden" className="form-control" name="reCaptchaEnable" value="0" />
                  <div className="dzFormMsg"></div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label">Enter Name</label>
                    <div className="input-group">
                      <input
                              required
                              type="text"
                              className="form-control"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label">Enter Email</label>
                    <div className="input-group">
                      <input
                              required
                              type="email"
                              className="form-control"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label">Enter Phone</label>
                    <div className="input-group">
                      <input
                              required
                              type="tel"
                              className="form-control"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label">Enter City</label>
                    <div className="input-group">
                      <input
                              required
                              type="text"
                              className="form-control"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                            />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <label className="form-label">Message</label>
                    <div className="input-group">
                      <textarea
                              rows="4"
                              required
                              className="form-control"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                            ></textarea>
                    </div>
                  </div>
                  <div>
                    <button name="submit" type="submit" value="submit" className="btn bg-green">
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
