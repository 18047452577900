import React from 'react'
import ProductPage from '../../components/ProductPage'

export const Product = () => {
  return (
    <div className='page product-page'>
        <ProductPage />
    </div>
  )
}
