import React, { useEffect, useState } from "react";
import productsData from "../product.json";
import { Link } from "react-router-dom";
const ProductPage = () => {
  const [products, setProducts] = useState({ fruits: [], vegetables: [], herbs: [] });

  useEffect(() => {
    setProducts(productsData);
  }, []);

  return (
    <section className="section pt-0">
      <div className="section-title mb-4">
        <h2>Our Products</h2>
      </div>
      <div className="container">
        {Object.entries(products).map(([category, items]) => (
          <div key={category} id={category}>
            <div className="product-title">
              <h4>Freeze Dried {category.charAt(0).toUpperCase() + category.slice(1)}</h4>
            </div>
            <div className="row">
              {items.map((product, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                  <div className="product-wrap">
                    <div className="product-image">
                      <img src={`../assets/images/products/${product.image}`} alt={product.name} />
                    </div>
                    <div className="product-detail">
                      <div className="product-name">{product.name}</div>
                      <div className="view-more">
                        <Link to={`/product/${category}/${product.name.toLowerCase().replace(/\s+/g, "_")}`} 
      className="btn btn-outline-primary">
  View Details
</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductPage;
