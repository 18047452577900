import React from 'react'
import { AboutPage } from '../../components/AboutPage'

export const About = () => {
  return (
    <div className='about-us'>
    <AboutPage />
    </div>
  )
}
