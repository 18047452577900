import React from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, FaMapSigns, FaMobile, FaEnvelope } from "react-icons/fa";


// Import Logo (If inside src/assets/)
// import logo from "../assets/images/KashyapbioLogo-1.png";

const socialLinks = [
  { icon: <FaFacebookF />, url: "#" },
  { icon: <FaTwitter />, url: "#" },
  { icon: <FaInstagram />, url: "#" },
];

const menuItems = ["About Us", "Products", "Application", "Contact Us"];

export const Footer = () => {
  return (
   <footer className="footer-box section pb-0">
      <div className="container">
        <div className="row">
          
          {/* About Us Section */}
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div className="footer-content">
              <h3>About Us</h3>
              <p>
                We are a young and dynamic firm equipped with a state-of-the-art manufacturing facility
                located at Karnavati Nagar (Ahmedabad), Bharat.
              </p>
              <ul className="socials-box footer-socials">
                {socialLinks.map((social, index) => (
                  <li key={index}>
                    <a href={social.url}>
                      <div className="social-circle-border">{social.icon}</div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Navigation Menu */}
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div className="footer-content">
              <h3>New Menu</h3>
              <ul>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <a href="/">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Contact Us */}
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div className="footer-content">
              <h3>Contact Us</h3>
              <p>
                <FaMapSigns /> A-105/106, Rameshwar Estate, <br />
                Oppo. Krishna Park Society, <br />
                Nr. Jay Chemical, Odhav, <br />
                Ahmedabad-382415
              </p>
              <p>
                <FaMobile /> +91 97241 60555
              </p>
              <p>
                <FaEnvelope /> <a href="mailto:info@kashyapbio.com">info@kashyapbio.com</a>
              </p>
            </div>
          </div>

          {/* Opening Hours */}
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div className="footer-content">
              <h3>Opening Hours</h3>
              <ul>
                <li>
                  <p>Monday - Saturday</p>
                  <span>09:00 AM - 06:00 PM</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div id="copyright" className="copyright-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h6 className="copy-title">
                Copyright © 2024 by Kashyap Bio Research. Designed by{" "}
                <a href="https://forolytechnologies.com/" target="_blank" rel="noopener noreferrer">
                  Foroly Technologies
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
