import React from 'react';
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import aboutUsImg from "../assets/images/home/about-us.jpeg";

export const AboutSection = () => {
  return (
   <div className="about-wrapper section">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} className="text-center mb-4">
            <div className="section-title">
              <h2>About Us</h2>
            </div>
          </Col>

          <Col lg={6} md={6} sm={12} data-aos="fade-right" data-aos-duration="1000">
            <Image src={aboutUsImg} alt="Spices" fluid />
          </Col>

          <Col lg={6} md={6} sm={12} data-aos="fade-left" data-aos-duration="1000">
            <p>
              We are pleased to introduce ourselves as a young and dynamic firm, equipped with a state-of-the-art
              manufacturing facility located at Karnavati Nagar (Ahmedabad), Bharat. We manufacture a wide variety of
              freeze-dried food products and other customized ingredients.
            </p>

            <p>
              We prove our capabilities & excellence through continuous research in maintaining uncompromised quality
              in freeze-dried products.
            </p>

            <Button variant="outline-primary" href="about.html" className="py-2 px-4">
              Read More
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
