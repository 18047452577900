import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import images
import aboutBio from "../assets/images/about/about-bio.jpeg";
import vaporizer from "../assets/images/about/vaporizer.jpeg";
import gasLevel from "../assets/images/about/gas_level_indicator.jpeg";
import petrolCorrection from "../assets/images/about/petrol_correction.jpeg";
import antiScale from "../assets/images/about/antiscale.jpeg";
import auraSeries from "../assets/images/about/aura-series.webp";
import apsServo from "../assets/images/about/APS-servo.webp";
import rotaryMachine from "../assets/images/about/rotary-machine.webp";
export const AboutPage = () => {

    const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="about-wrapper">
      <div className="container">
        
        {/* Section Title */}
        <div className="section-title">
          <h2>About Us</h2>
        </div>

        {/* Introduction */}
        <div className="row align-items-center">
          <div className="about text-center mb-4">
            <div className="sub-title">
              <h4>Welcome to Kashyap Group<br />(Since 2003)</h4>
            </div>
            <p>
              Introduced in "2003", Kashyap Group achieved milestones in various business fields through 
              aggressive expansion and growth in service of mother earth and mankind.
            </p>
          </div>

          {/* Kashyap Bio Research */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="about-content">
              <h5>Kashyap Bio Research</h5>
              <p>
                Kashyap Bio Research is committed to providing a wide variety of freeze-dried food ingredients 
                (Fruits, Vegetables, Herbs, and customized food mixtures) with premium quality and consistency. 
                We conduct continuous R&D to introduce new products and improve existing ones.
              </p>
              <p>
                We follow global food safety guidelines and believe in "Client First - Always". Feel free to 
                contact us at{" "}
                <a href="mailto:info@kashyapbio.com">info@kashyapbio.com</a>.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={aboutBio} alt="Freeze Dried Products" className="img-fluid" />
          </div>
        </div>

        {/* Our History Section */}
        <div className="section">
          <div className="sub-title text-center mb-5">
            <h3><em>Our History</em></h3>
          </div>

          {/* Kashyap Auto Electronics */}
          <div className="row align-items-center row-reverse mb-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Slider {...sliderSettings}>
                <img src={vaporizer} alt="Vaporizer" />
                <img src={gasLevel} alt="Gas Level Indicator" />
                <img src={petrolCorrection} alt="Petrol Correction" />
              </Slider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="about-content">
                <h5>Kashyap Auto Electronics</h5>
                <p>
                  Introduced in 2003 in Ahmedabad, Gujarat. Our commitment to a sustainable automotive industry is 
                  powered by innovation in manufacturing CNG kits for cars, reducing emissions, and improving fuel 
                  efficiency. Visit us at{" "}
                  <a href="https://kae.co.in/" target="_blank" rel="noopener noreferrer">
                    Kashyap Auto Electronics
                  </a>.
                </p>
              </div>
            </div>
          </div>

          {/* Kashyap Technology */}
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="about-content">
                <h5>Kashyap Technology</h5>
                <p>
                  We specialize in **Water Treatment Technology**, improving water quality for industrial and agricultural 
                  use with minimal resource consumption. Our anti-scaling machines are available in various capacities.
                  Contact us at{" "}
                  <a href="https://kashyaptechnology.com/" target="_blank" rel="noopener noreferrer">
                    Kashyap Anti Scale
                  </a>.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img src={antiScale} alt="Anti Scale" className="img-fluid" />
            </div>
          </div>

          {/* Kashyap Industries */}
          <div className="row align-items-center row-reverse">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Slider {...sliderSettings}>
                <img src={rotaryMachine} alt="Food Processing" />
                <img src={auraSeries} alt="Aura Series" />
                <img src={apsServo} alt="Cold Storage" />
              </Slider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="about-content">
                <h5>Kashyap Industries</h5>
                <p>
                  "Kashyap Industries" is our engineering firm, introduced in 2005, specializing in **Plastic Injection 
                  Molding Technology**. We design energy-efficient, accurate, and compact machines tailored to client needs.
                  Visit us at{" "}
                  <a href="https://www.mk5.in/" target="_blank" rel="noopener noreferrer">
                    Kashyap Industries (MK5)
                  </a>.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
