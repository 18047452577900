import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { MyNavbar } from "./components/Navbar";
import { Home } from "./pages/home/Home";
import { About } from "./pages/about/About";
import { Product } from "./pages/product/Product";
// import { Contact } from "./pages/contact/Contact";
import { Footer } from "./components/Footer";
import Breadcrumb from "./components/Breadcrumb";
import ProductPage from "./components/ProductPage";
import ProductDetail from "./components/ProductDetail";
import ProcessSection from "./components/ProcessSection";
import ApplicationSection from "./components/ApplicationSection";
import { Contact } from "./pages/contact/Contact";

// import Products from "./pages/Products";
// import Contact from "./pages/Contact";
function App() {
  const location = useLocation();
  return (
    <div className="App">
      <MyNavbar />
      {/* Only show Breadcrumb if not on the home page */}
            {location.pathname !== "/" && <Breadcrumb />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/product" element={<Product />} />
        <Route path="/" element={<ProductPage />} />
        <Route path="/product/:category/:productName" element={<ProductDetail />} />
        <Route path="/process" element={<ProcessSection />} />
        <Route path="/applications" element={<ApplicationSection />} />
        <Route path="/contact" element={<Contact />} />


        {/* <Route path="*" element={<NotFound />} />  */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
