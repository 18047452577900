import React from 'react'
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";

export const MyNavbar = () => {
  return (
    <Navbar expand="lg" fixed="top">
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand as={Link} to="/">
          <img 
            src="KashyapbioLogo-1.png" 
            alt="Logo" 
          />
        </Navbar.Brand>

        {/* Navbar Toggle for Mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
            <Nav.Link as={Link} to="/product">Products</Nav.Link>
            <Nav.Link as={Link} to="/process">Process</Nav.Link>
            <Nav.Link as={Link} to="/applications">Application</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
