import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Import Images
import vegSlide from '../assets/images/home/veg-slide.jpeg';
import fruitSlide from '../assets/images/home/fruit-slide.jpeg';
import herbsSlide from '../assets/images/home/herbs-slide.png';

const images = [vegSlide, fruitSlide, herbsSlide];

const Banner = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed (3 seconds)
    arrows: true, // Show Next/Prev buttons
  };

  return (
    <div className="banner">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div className="slider-wrap" key={index}>
            <div className="banner-image">
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          </div>
        ))}
      </Slider>
      <div className="banner-content">
        <h1>Welcome to Kashyap Bio Research</h1>
        <p>Live Natural, Live Healthy</p>
      </div>
    </div>
  );
};

export default Banner;
