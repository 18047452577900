import { Link, useLocation } from "react-router-dom";
import breadcrumbImage from '../assets/images/breadcrumb.jpg';
const Breadcrumb = () => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    const formatText = (str) =>
        str
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word

    // List of categories that should be plain text
    const nonLinkCategories = ["fruits", "vegetables", "herbs"];

    return (
        <div 
            className="dz-bnr-inr mb-5" 
            style={{ backgroundImage: `url(${breadcrumbImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
            <div className="container">
                <div className="dz-bnr-inr-entry">
                    <nav aria-label="breadcrumb" className="breadcrumb-row">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            {pathnames.map((value, index) => {
                                const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                                const isLast = index === pathnames.length - 1;
                                const isNonLinkCategory = nonLinkCategories.includes(value.toLowerCase()); // Check if it’s a non-link category
                                const formattedValue = formatText(value);

                                return (
                                    <li key={to} className="breadcrumb-item">
                                        <span className="mx-2">/</span>
                                        {isLast || isNonLinkCategory ? (
                                            <span>{formattedValue}</span>
                                        ) : (
                                            <Link to={to}>{formattedValue}</Link>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;
