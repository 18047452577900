import React from "react";
import { useParams } from "react-router-dom";
import productsData from "../product.json";

const ProductDetail = () => {
  const { category, productName } = useParams();

  // Find the product
  const product = productsData[category]?.find(
    (item) => item.name.toLowerCase().replace(/\s+/g, "_") === productName
  );

  if (!product) {
    return <h2>Product not found</h2>;
  }

  return (
    <div className="page product-detail-page">
    <section className="section pt-0">
      <div className="section-title mb-4">
        <h2>Product Details</h2>
      </div>
      <div className="container">
        <div className="detail-wrap">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="product-image">
                <img
                  src={`/assets/images/products/${product.image}`}
                  alt={product.name}
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="product-detail">
                <div className="product-name fs-3 mb-3">{product.name}</div>
                <div className="price-detail">
                  <p>
                    <b>25 Kilograms</b> (Minimum Order Quantity)
                  </p>
                </div>
                <div className="product-table">
                  <table className="w-100 mb10px formTable lcBdr0">
                    <tbody>
                      <tr>
                        <td className="p5px alpha75">Business Type</td>
                        <td className="p5px fw-bold">Manufacturer, Supplier</td>
                      </tr>
                      <tr>
                        <td className="p5px alpha75">Type</td>
                        <td className="p5px fw-bold">{product.name}</td>
                      </tr>
                      <tr>
                        <td className="p5px alpha75">Cultivation Type</td>
                        <td className="p5px fw-bold">Common</td>
                      </tr>
                      <tr>
                        <td className="p5px alpha75">Shelf Life</td>
                        <td className="p5px fw-bold">2 Years</td>
                      </tr>
                      <tr>
                        <td className="p5px alpha75">Packaging</td>
                        <td className="p5px fw-bold">Laminated Plastic Bag</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="product-buyer product-table">
                  <p className="fs-5 mt-3 mb-0">Preferred Buyer From</p>
                  <table className="w-100 mb10px formTable">
                    <tbody>
                      <tr>
                        <td className="p5px alpha75">Location</td>
                        <td className="p5px fw-bold">Worldwide</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="product-inquiry">
                  <div className="mt-4 proDtlsBtn">
                    <a href="/" className="btn btn-outline-primary">
                      <i className="fa fa-phone large mr2px vam"></i> Request to Call
                    </a>
                    <a href="/" className="btn bg-green">
                      <i className="fas fa-envelope"></i> Send Enquiry
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default ProductDetail;
