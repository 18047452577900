import React from 'react'
// Import Images (if inside src/)
import icon1 from "../assets/images/home/icon-1.png";
import icon2 from "../assets/images/home/icon-2.png";
import icon3 from "../assets/images/home/icon-3.png";

const features = [
  {
    id: 1,
    title: "Natural Process",
    description:
      "Food is processed by changing physical conditions only inside freeze dryer without any additional additives.",
    img: icon1,
    animationDuration: "1000",
  },
  {
    id: 2,
    title: "Organic Products",
    description:
      "Fresh food comes directly from organic farms. Food is then washed in an ultra-sonic bath which removes surface impurities & residual pesticides if any.",
    img: icon2,
    animationDuration: "2000",
  },
  {
    id: 3,
    title: "Longer Shelf Life",
    description:
      "Products are dried until bound moisture is removed. After that, food does not decompose or rot for a longer period—up to 3 years.",
    img: icon3,
    animationDuration: "3000",
  },
];

export const FeaturesSection = () => {
  return (
    <div className="feature-wrapper section">
      <div className="container">
        <div className="section-title">
          <h1 className="display-5 mb-3">Our Features</h1>
          <p>
            Preserving Taste and Texture with Advanced Freeze-Drying, Long Shelf Life without Compromising Quality
          </p>
        </div>

        <div className="row g-4">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="col-lg-4 col-md-4 col-sm-12 aos-init"
              data-aos="fade-up"
              data-aos-duration={feature.animationDuration}
            >
              <div className="bg-white text-center h-100 p-4 p-xl-5">
                <img className="img-fluid mb-4" src={feature.img} alt={feature.title} />
                <h4 className="mb-3">{feature.title}</h4>
                <p className="mb-4">{feature.description}</p>
                {/* Uncomment if "Read More" is needed */}
                {/* <a className="btn btn-outline-primary py-2 px-4" href="#">Read More</a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
